<template>

    <section class="contents">

    <div class="contentInner">
        <div class="profile_photo" v-if="mode !== 'edit'">
            <div class="image-container">
                <div class="image-canvas">
                    <img v-if="image_b64" :src="image_b64" alt="プロフィール写真">
                    <img v-if="!image_b64" src="@/assets/img/signup/add_photo2.png" alt="プロフィール写真">
                </div>
            </div>
        </div>

        <div class="white_bg_area">

        <div class="profile_item">
            <div class="item_label">グループ名</div>
            <div class="item-bold">{{ nick }}</div>
        </div>

        <div class="profile_item">
            <div class="item_label">申請者お名前</div>
            <div class="item-bold">{{ last_name }} {{ first_name }}</div>
        </div>

        <div class="profile_item">
            <div class="item_label">フリガナ</div>
            <div class="item-bold">{{ last_kana }} {{ first_kana }}</div>
        </div>

        <div class="profile_item">
            <div class="item_label">生年月日</div>
            <div class="item-bold">{{ birthday }}</div>
        </div>

        <div class="profile_item">
            <div class="item_label">メールアドレス</div>
            <div class="item-bold">{{ email }}</div>
        </div>

        <div class="profile_item">
            <div class="item_label">パスワード</div>
            <div class="item-bold">{{ password }}</div>
        </div>

        <div class="profile_item">
            <div class="item_label">ひと言(グループ説明)
            </div>
            <div class="item-bold">{{ comment }}</div>
        </div>

        <div class="profile_item">
            <div class="item_label">SNS</div>
            <div>
            <img v-if="twitter" src="@/assets/img/logo_twitter.png" alt="Twitterロゴ">
            <img v-if="facebook" src="@/assets/img/logo_facebook.png" alt="Facebookロゴ">
            <img v-if="instagram" src="@/assets/img/logo_instagram.png" alt="Instagramロゴ">
            </div>
        </div>

        <div class="bt_form mb_20"><button type="button" value="send" @click="send">送信</button></div>

        </div><!-- .white_bg_area -->

    </div><!--.contentInner-->
    </section>

</template>

<script>
export default {
    name: 'SignupConfirm',
    props: {
    nick: String,
    last_name: String,
    first_name: String,
    last_kana: String,
    first_kana: String,
    birthday: String,
    email: String,
    password: String,
    password_confirm: String,
    comment: String,
    twitter: String,
    facebook: String,
    instagram: String,
    image_b64: String,
    mode: String,
    },
    data: function () {
    return {
    }
    },
    methods: {
    send () {
        this.$emit('send');
        // this.$modal.hide('send');
    }
    }
}
</script>

<style lang="scss" scoped>
.item-bold {
    font-weight: bold;
}
.profile_item {
    padding-bottom: 20px;
}
.image-canvas {
width: 100%;
height: 100%;
overflow: hidden;
margin: 0 auto;
text-align: center;
position: relative;
display: inline-block;
}
.image-canvas img {
width: 100%;
height: 100%;
object-fit: cover; 
}
.image-container {
width: 100px;
margin: 0 auto;
text-align: center;
}

</style>