<template>
    <article id="wrap">

    <form novalidate="true">

        <header>
        <ul class="page_head">
            <li class="menuL" v-if="!isConfirm"><router-link :to="{name:'login'}"><img src="@/assets/img/icon_back.png"></router-link></li>
            <li class="menuL" v-if="isConfirm"><a @click="back"><img src="@/assets/img/icon_back.png"></a></li>
            <li class="menuC"><h1 class="page_head_h1">アーティスト登録</h1></li>
            <li class="menuR"></li>
        </ul>
        </header>


        <section v-if="!isConfirm" class="contents">

        <div class="contentInner">
            <MyUploadArtist :image_b64="image_b64" @uploadImage="updateImage"></MyUploadArtist>

            <SignupArtistFormProfile ref="profile" v-bind.sync="form" @update:error="setError"></SignupArtistFormProfile>
            <SignupFormSNS v-bind.sync="form" @update:error="setError"></SignupFormSNS>

            <div class="formCheckbox">
            <div class="error" v-if="error && !accepted">
                同意するにチェックが入っていません
            </div>
            <input type="checkbox" v-model="accepted" id="checkbox01">
            <label for="checkbox01" class="checkbox">
                <router-link :to="{name: 'rule'}" target="_blank">利用規約</router-link>に同意する。
            </label>
            </div>

            <div class="bt_form"><button type="button" value="send" @click="next">確認</button></div>

        </div><!--.contentInner-->

        </section><!--.contents-->

        <SignupArtistConfirm v-if="isConfirm" v-bind="form" :image_b64="image_b64" @send="send"></SignupArtistConfirm>

    </form>

    <modal class="modal-wrapper" name="send" :adaptive="true" :scrollable="true"
            classes="modal-window" height="auto">
        <div class="modal-content">
        <div class="mb_20">登録されたメールアドレス宛にご案内を送信しました<br>
            届いたメールに記載されたURLをクリックして登録を完了してください。</div>
        <div class="bt_form mb_5"><a @click="finish">OK</a></div>
        </div>
    </modal>
    </article>
</template>

<script>
import SignupArtistFormProfile from '@/components/SignupArtistFormProfile';
import SignupFormSNS from '@/components/SignupFormSNS';
import SignupArtistConfirm from '@/components/SignupArtistConfirm';
import MyUploadArtist from '@/components/MyUploadArtist';
export default {
    name: 'SignupArtistForm',
    components: {SignupArtistFormProfile, SignupFormSNS, SignupArtistConfirm, MyUploadArtist},
    data: function () {
    return {
        form: {
        nick: '',
        first_name: '',
        last_name: '',
        first_kana: '',
        last_kana: '',
        birthday: '',
        email: '',
        password: '',
        password_confirm: '',
        comment: '',
        twitter: '',
        facebook: '',
        instagram: '',
        },
        image_b64: '',
        accepted: false,
        error: false,
        isConfirm: false,
    }
    },
    methods: {
    back () {
        this.$set(this, 'isConfirm', false);
        setTimeout(() => { this.$refs.profile.$v.$touch() }, 100);
    },
    next () {
        // validation
        this.error = this.$refs.profile.invalid();
        // 追加のバリデーション 利用規約チェック必須
        if (!this.accepted) {
        this.error = true;
        }

        if (!this.error) {
        this.isConfirm = true;
        // this.$router.push({name:'signup-confirm'});
        } else {
        document.scrollingElement.scrollTop = 0;
        }
    },
    setError ($event) {
        this.error = $event;
    },
    async updateImage ($event) {
        this.image_b64 = $event;
    },
    async send () {
        try {
        await this.$http.post('artists', Object.assign(
            this.form, {
                image_b64: this.image_b64
            }));
        } catch (e) {
        console.log(e.response);
        this.$swal(e.message);
        this.error = true;
        this.isConfirm = false;
        return;
        }

        this.$modal.show('send')
        // this.$http.post('users', this.form);
    },
    finish () {
        this.$router.push({name: 'home'});
    }
    }
}
</script>

<style lang="scss" scoped>
#wrap {
    .error {
    font-size: 10rem;
    color: #ff7666;
    margin: 5px 0 -5px;
    }
}

</style>