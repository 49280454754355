<template>
  <div class="profile_photo">
    <input ref="file" class="file-button" type="file" accept="image/png,image/jpeg" @change="upload" />
    <a @click="clickButton">
      <div class="image-container">
        <div class="image-canvas">
          <img v-if="!image_b64" :src="thisImage | imageUrl | thumbnail" alt="プロフィール写真"><span v-if="!image_b64" class="image-plus">+</span>
          <img v-if="image_b64" :src="image_b64" alt="プロフィール写真">
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'MyUpload',
  props: {
    image: String,
    image_b64: String,
  },
  computed: {
    loginUser () {
      return this.$store.state.loginUser;
    },
    thisImage () {
      if (this.image_b64) {
        return this.image_b64;
      }
      if (this.image) {
        return this.image;
      } else {
        return "storage/images/users/add_photo2.png";
      }
    }
  },
  methods: {
    clickButton () {
      this.$refs.file.click();
    },
    async upload () {
      this.$store.commit('setLoading');
      const files = event.target.files || event.dataTransfer.files;
      const file = files[0];

      if (this.checkFile(file)) {
        const picture = await this.getBase64(file);
        this.$emit('uploadImage', picture);
      } else {
        this.$toasted.error('画像をアップロードできませんでした', {
          position: 'top-center',
          duration: 2000,
        });
      }
      this.$store.commit('resetLoading');
      return false;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    checkFile(file) {
      let result = true
      const SIZE_LIMIT = 10000000 // 10MB
      // キャンセルしたら処理中断
      if (!file) {
        result = false
      }
      // jpeg か png 関連ファイル以外は受付けない
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        result = false
      }
      // 上限サイズより大きければ受付けない
      if (file.size > SIZE_LIMIT) {
        result = false
      }
      return result
    }
  }
}
</script>

<style scoped>
.file-button {
  display: none;
}
.profile_photo {
  font-size:62.5%;
}
.image-canvas {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  text-align: center;
  position: relative;
  display: inline-block;
}
.image-canvas img {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}
.image-plus {
  font-size: large;
  padding-top: 80px;
  padding-right: 20px;
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
}
.image-container {
  width: 100px;
  margin: 0 auto;
  text-align: center;
}
</style>
